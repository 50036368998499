import React, { useState, useEffect } from 'react';
import Login from 'wever-core/dist/components/security/login';
import Security from 'wever-core/dist/services/security';
import WeverDashboard from 'wever-dashboard';

import 'wever-dashboard/dist/styles/bootstrap.css';
import 'wever-maps/dist/styles/bootstrap.css';
import 'wever-graph/dist/styles/bootstrap.css';

import './index.css';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const dashboardIdParams = urlParams.get('id');
  const report = urlParams.get('report');
  const community = urlParams.get('community');
  const [dashboardId , setDashboardId] = useState();

  useEffect(() => {
    return () => null;
  }, [report, token, dashboardIdParams, community])

  if (!Security.token && !token) {
    return <Login />;
  }

  if (!dashboardId && !dashboardIdParams) {
    return <input value={dashboardId} onChange={(e) => setDashboardId(e.target.value)} />;
  }

  return (
    <div className="App">
      <WeverDashboard locale="fr" token={token || Security.token} editionMode dashboard={dashboardIdParams || dashboardId} report={report} community={community} />
    </div>
  );
}

export default App;
